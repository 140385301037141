import axios from 'axios.js'
import { get } from 'lodash'
import { setLoading } from "app/redux/actions/LoadingActions";
import { setMessage } from "app/redux/actions/MessageActions";

export const GET_CAMPAIGN_REPORT_MATRIX_PER_DAY = "GET_CAMPAIGN_REPORT_MATRIX_PER_DAY";
export const GET_WEEKLY_CAMPAIGN_REPORT_MATRIX_PER_CAMPAIGN = "GET_WEEKLY_CAMPAIGN_REPORT_MATRIX_PER_CAMPAIGN";
export const GET_MARKETING_AI_ANALYTICS = "GET_MARKETING_AI_ANALYTICS";

export const getCampaignReportMatrixPerDay = () => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        try {
            const response = await axios.get('/marketing/report/matrix-per-day');
            const weeklyStatusChartData = get(response, "data.data", []);

            dispatch({
                type: GET_CAMPAIGN_REPORT_MATRIX_PER_DAY,
                payload: {
                    weeklyStatusChartData,
                },
            })
        } catch (error) {
            dispatch(setMessage({ message: error.message, variant: "error" }));
        } finally {
            dispatch(setLoading(false))
        }
    }
}

export const getWeeklyCampaignReportMatrixPerCampaign = () => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        try {
            const response = await axios.get('/marketing/report/matrix-per-campaign');
            const weeklyCampaignChartData = get(response, "data.data", []);

            dispatch({
                type: GET_WEEKLY_CAMPAIGN_REPORT_MATRIX_PER_CAMPAIGN,
                payload: {
                    weeklyCampaignChartData,
                },
            })
        } catch (error) {
            dispatch(setMessage({ message: error.message, variant: "error" }));
        } finally {
            dispatch(setLoading(false))
        }
    }
}

export const getMarketingAiAnalytics = () => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        dispatch({
            type: GET_MARKETING_AI_ANALYTICS,
            payload: {
                aiAnalyticsData: null,
            },
        })
        try {
            const response = await axios.get('/marketing/ai/get-analytics');
            const aiAnalyticsData = get(response, "data.data", null);

            dispatch({
                type: GET_MARKETING_AI_ANALYTICS,
                payload: {
                    aiAnalyticsData,
                },
            })
        } catch (error) {
            dispatch(setMessage({ message: error.message, variant: "error" }));
        } finally {
            dispatch(setLoading(false))
        }
    }
}
