import { combineReducers } from 'redux'
import ScrumBoardReducer from './ScrumBoardReducer'
import NotificationReducer from './NotificationReducer'
import EcommerceReducer from './EcommerceReducer'
import NavigationReducer from './NavigationReducer'
import LoadingReducer from './LoadingReducer'
import UserReducer from './UserReducer'
import TeamsReducer from './TeamsReducer'
import TagReducer from './TagReducer'
import UserTagReducer from './UserTagReducer'
import ImageUploadReducer from './ImageUploadReducer'
import CommonReducer from './CommonReducer'
import MessageReducer from './MessageReducer'
import CartReducer from './CartReducer'
import UserProductsReducer from './UserProductsReducer'
import ProductReducer from './ProductReducer'
import FindInformationReducer from './FindInformationReducer'
import WidgetReducer from './WidgetReducer'
import SitesReducer from './SitesReducer'
import SmeReducer from './SmeReducer'
import CompanyReducer from './CompanyReducer'
import FunderReducer from './FunderReducer'
import MarketingReducer from './MarketingReducer'
import MarketingReportReducer from './MarketingReportReducer'

const RootReducer = combineReducers({
    notifications: NotificationReducer,
    navigations: NavigationReducer,
    scrumboard: ScrumBoardReducer,
    ecommerce: EcommerceReducer,
    loading: LoadingReducer,
    users: UserReducer,
    teams: TeamsReducer,
    tags: TagReducer,
    userTags: UserTagReducer,
    imageUpload: ImageUploadReducer,
    common: CommonReducer,
    message: MessageReducer,
    products: ProductReducer,
    cart: CartReducer,
    userProducts: UserProductsReducer,
    findInformation: FindInformationReducer,
    widget: WidgetReducer,
    sites: SitesReducer,
    smes: SmeReducer,
    company: CompanyReducer,
    funder: FunderReducer,
    marketing: MarketingReducer,
    MarketingReport: MarketingReportReducer,
})

export default RootReducer
