import {
    GET_CAMPAIGN_REPORT_MATRIX_PER_DAY,
    GET_WEEKLY_CAMPAIGN_REPORT_MATRIX_PER_CAMPAIGN,
    GET_MARKETING_AI_ANALYTICS,
} from '../actions/MarketingReportActions'

const initialState = {
    weeklyStatusChartData: [],
    weeklyCampaignChartData: [],
    aiAnalyticsData: null,
}

const MarketingReportReducer = function (state = initialState, action) {
    switch (action.type) {
    case GET_CAMPAIGN_REPORT_MATRIX_PER_DAY: {
        return {
            ...state,
            ...action.payload,
        }
    }
    case GET_WEEKLY_CAMPAIGN_REPORT_MATRIX_PER_CAMPAIGN: {
        return {
            ...state,
            ...action.payload,
        }
    }
    case GET_MARKETING_AI_ANALYTICS: {
        return {
            ...state,
            ...action.payload,
        }
    }
    default: {
        return {
            ...state,
        }
    }
    }
}

export default MarketingReportReducer;
