import {
    GET_DATA_SOURCES_LIST,
} from '../actions/MarketingActions'

const initialState = {
    dataSources: null,
}

const MarketingReducer = function (state = initialState, action) {
    switch (action.type) {
    case GET_DATA_SOURCES_LIST: {
        return {
            ...state,
            ...action.payload,
        }
    }
    default: {
        return {
            ...state,
        }
    }
    }
}

export default MarketingReducer
