import axios from 'axios.js'
import { get } from 'lodash'
import { setLoading } from "app/redux/actions/LoadingActions";
import { setMessage } from "app/redux/actions/MessageActions";

export const GET_DATA_SOURCES_LIST = "GET_DATA_SOURCES_LIST";
export const UPDATE_DATA_SOURCE = "UPDATE_DATA_SOURCE";
export const DELETE_DATA_SOURCE = "DELETE_DATA_SOURCE";

export const getDataSources = () => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        try {
            const response = await axios.get('/marketing/data-source/list');
            const dataSources = get(response, "data.data[0]", {});

            dispatch({
                type: GET_DATA_SOURCES_LIST,
                payload: {
                    dataSources,
                },
            })
        } catch (error) {
            dispatch(setMessage({ message: error.message, variant: "error" }));
        } finally {
            dispatch(setLoading(false))
        }
    }
}

export const updateDataSource = (data) => {
    return (dispatch) => {
        axios.post('/marketing/data-source', data).then(response => {
            dispatch(setMessage({
                message: get(response, 'data.message', 'Data Source Updated'),
                variant: "success",
            }));
            dispatch(getDataSources());

            return response
        }).catch((error) => {
            dispatch(setMessage({ message: error.message, variant: "error" }));
        });
    }
}

export const deleteDataSource = (data) => {
    return (dispatch) => {
        axios.delete('/marketing/data-source', { data }).then(response => {
            dispatch(setMessage({
                message: get(response, 'data.message', 'Data Source Deleted'),
                variant: "success",
            }));
            dispatch(getDataSources());

            return response
        }).catch((error) => {
            dispatch(setMessage({ message: error.message, variant: "error" }));
        });
    }
}
