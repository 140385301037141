import React, { lazy } from "react";
import Loadable from "app/components/Loadable/Loadable";
import { authRoles } from "app/auth/authRoles";

const MarketingDashboard = Loadable(lazy(() => import("./Dashboard")));
const ManageConnections = Loadable(lazy(() => import("./ManageConnections")));
const AIInsights = Loadable(lazy(() => import("./AIInsights")));

const marketingRoutes = [
    {
        path: "/marketing-dashboard",
        element: <MarketingDashboard />,
        auth: [authRoles.all],
        level: 1,
    },
    {
        path: "/manage-marketing-connections",
        element: <ManageConnections />,
        auth: [authRoles.all],
        level: 2,
    },
    {
        path: "/ai-insights",
        element: <AIInsights />,
        auth: [authRoles.all],
        level: 2,
    },
];

export default marketingRoutes;
